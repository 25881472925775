import React from 'react'

const DownloadArrow = ({ className }) => (
  <svg className={className} width="24" height="27" viewBox="0 0 24 27" xmlns="http://www.w3.org/2000/svg">
    <g className="stroke-current" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none" fillRule="evenodd">
      <path d="M20 14.282L12.02 21 4 14.282M12 21V1M23 23v3H1v-3"/>
    </g>
  </svg>
)

export default DownloadArrow
