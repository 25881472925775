import React from 'react'
import tw from 'tailwind.macro'
import DownloadArrow from '../../Shapes/DownloadArrow'
import Button from './Button'

const DownloadButton = ({ downloadURL, children, ...rest }) => {
  return (
    <a
      css={tw`no-underline inline-block`}
      href={downloadURL}
      download
      { ...rest }
    >
      <Button
        isPrimary
        Icon={DownloadArrow}
        css={tw`w-full`}
      >
        { children }
      </Button>
    </a>
  )
}

export default DownloadButton
